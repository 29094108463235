import React from 'react'
import { createContext, useEffect, useState } from 'react';
const $ = window.$;

export const HubContext = createContext();
export const HubProvider = ({ children }) => {
    const [proxy, setProxy] = useState(null);
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        const hubConnection = $.hubConnection();
        const jobHubProxy = hubConnection.createHubProxy('JobHub');

        setConnection(hubConnection);
        setProxy(jobHubProxy);
        hubConnection.start().done(function () {
            console.log("Connected");
        });

        return () => hubConnection.stop();
    }, []);


    return (
        <HubContext.Provider
            value={{ connection, proxy }}
        >
            {children}
        </HubContext.Provider>
    );
};
