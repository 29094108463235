import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

const baseurl = '/headless'
let companyCache = {};

const Api = (function () {
    // Use this in the get jobs request, we can wait and see if there is a delete request that needs to finish before we get the updated job
    let deleteRequest;

    async function getArticles(collection) {
        const request = await fetch(baseurl + '/collections/' + collection);
        return await request.json();
    }
    async function getPageTree() {
        const request = await fetch(baseurl + '/pages');
        const pageTree = await request.json();
        console.log(pageTree)
        return pageTree;
    }

    async function getJobs() {
        if (deleteRequest) {
            await deleteRequest;
        }
        const request = await fetch(baseurl + '/jobs/');
        return (await request.json())?.map(job => {
            job.availableForPickup = dayjs(job.availableForPickup);
            // job.deliverBefore = job.availableForPickup.add(job.deliverBefore, 'days');
            job.temporarilyReserveUntil = dayjs(job.temporarilyReserveUntil)

            return job;
        });

    }

    async function getCompany(id) {

        // Simple cache function combining async and Promise.then to make things harder for Lasse... And to avoid sending hundreds of requests a the same time.
        if (companyCache[id]) {
            return await companyCache[id]
        }
        companyCache[id] = fetch(baseurl + '/company?id=' + id).then(async request => {
            const result = await request.json();
            if (request.status !== 200) {
                companyCache[id] = false;
                throw ("Error fetching company " + id)
            }
            return result;
        })

        return companyCache[id];
    }

    async function getJob(id) {
        const request = await fetch(baseurl + '/jobs/' + id);
        let job = await request.json();
        job.availableForPickup = dayjs(job.availableForPickup);
        job.temporarilyReserveUntil = dayjs(job.temporarilyReserveUntil)

        return job;
    }

    async function reserveTrip(id) {
        const request = await fetch(baseurl + '/jobs/reserve', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id })
        });

        let result = await request.json();
        if (result.error) {
            throw (result.error);
        }
        let job = result.data;
        job.availableForPickup = dayjs(job.availableForPickup);
        job.deliverBefore = dayjs(job.deliverBefore);
        job.temporarilyReserveUntil = dayjs(job.temporarilyReserveUntil)
        localStorage.setItem('reservationId', job.temporaryReservationId);
        return job;
    }

    async function bookTrip(id) {
        const temporaryReservationId = localStorage.getItem('reservationId');

        const request = await fetch(baseurl + '/jobs/book', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id, temporaryReservationId })
        });

        let result = await request.json();
        if (result.error) {
            throw (result.error);
        }

        localStorage.removeItem('reservationId');

        return true;
    }

    async function getTempReservation(id) {

        const temporaryReservationId = localStorage.getItem('reservationId');
        if (!temporaryReservationId) {
            throw ("no temp reservation")
        }
        const request = await fetch(baseurl + '/jobs/reservation/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id, temporaryReservationId })
        });

        let response = await request.json();
        if (response.error) {
            localStorage.removeItem('reservationId');
            throw (response.error)
        }
        let job = response;

        job.availableForPickup = dayjs(job.availableForPickup);
        job.deliverBefore = dayjs(job.deliverBefore);
        return job;
    }


    async function deleteReservation(id) {

        const temporaryReservationId = localStorage.getItem('reservationId');
        localStorage.removeItem('reservationId');

        deleteRequest = fetch(baseurl + '/jobs/deletereservation/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id, temporaryReservationId })
        });

        let request = await deleteRequest;
        let response = await request.json();
        if (response.error) {
            localStorage.removeItem('reservationId');
            throw (response.error)
        }

        return true;
    }

    return {
        getJob, getJobs, getTempReservation, deleteReservation, getArticles, bookTrip, reserveTrip, getPageTree, getCompany
    }
})();

export default Api;
