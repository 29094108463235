import React from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { useEffect } from "react";
import Company from "../components/company/company";
import Countdown from "../components/countdown/countdown";
import FadeIn from "react-fade-in";

// Helpers
import { labels, language } from "../helpers/markup.helper";
import Form, {
    validateEmail,
    validateEmpty,
    validatePhone,
    validatePersonNumber,
} from "../components/form/form";
import Heading from "../components/heading";
import Job from "../components/job";
import LoadingSpinner from "../components/loadingSpinner/loadingSpinner.js";
import Api from "../helpers/api.helper";
import {
    faClock,
    faEdit,
    faEnvelope,
    faIdBadge,
    faList,
    faLock,
    faMobileAlt,
    faSortNumericDownAlt,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import CardHeading from "../components/card/card-heading";
import CardBody from "../components/card/card-body";
import Card from "../components/card/card";
import moment from "moment";
import dayjs from "dayjs";
import Toc from "../components/toc";
export default function Reserve() {
    let { id } = useParams();
    const [job, setJob] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState();
    const temporaryReservationId = localStorage.getItem("reservationId");
    const [booked, setBooked] = useState(
        temporaryReservationId === job?.temporaryReservationId
    );
    const [bookingForm, updateForm] = useState();

    useEffect(() => {
        if (id) {
            Api.getJob(id)
                .then((job) => {
                    setJob(job);
                    setLoading(false);
                    Api.getCompany(job.company).then((company) => {
                        setCompany(company);
                        console.log(company);
                        updateForm({
                            name: {
                                value: "",
                                type: "text",
                                validator: validateEmpty,
                                placeholder: labels[language]?.name,
                                icon: faUser,
                                required: true,
                            },
                            email: {
                                value: "",
                                type: "email",
                                validator: validateEmail,
                                placeholder: labels[language]?.email,
                                icon: faEnvelope,
                                required: true,
                            },
                            // personNumber: {
                            //     value: "",
                            //     type: 'text',
                            //     validator: validatePersonNumber,
                            //     placeholder: 'Personnummer',
                            //     icon: faIdBadge,
                            //     required: true

                            // },
                            phone: {
                                value: "",
                                type: "text",
                                validator: validatePhone,
                                placeholder: labels[language]?.phone,
                                icon: faMobileAlt,
                                required: true,
                            },
                            pickupDate: {
                                value: job.availableForPickup.isAfter(dayjs())
                                    ? job.availableForPickup
                                          .hour(9)
                                          .startOf("hour")
                                          .format("YYYY-MM-DDTHH:mm")
                                    : dayjs()
                                          .add(0, "day")
                                          .hour(9)
                                          .startOf("hour")
                                          .format("YYYY-MM-DDTHH:mm"),
                                type: "datepicker",
                                validator: validateEmpty,
                                placeholder: "Ønsket hentedato",
                                min: (form) => {
                                    if (
                                        job.availableForPickup.isAfter(dayjs())
                                    ) {
                                        return job.availableForPickup
                                            .hour(9)
                                            .startOf("hour")
                                            .format("YYYY-MM-DDTHH:mm");
                                    }

                                    return dayjs()
                                        .add(0, "day")
                                        .hour(9)
                                        .startOf("hour")
                                        .format("YYYY-MM-DDTHH:mm");
                                },
                                max: () => {
                                    let inFive = job.availableForPickup
                                        .add(job.bookingOpenDays || 5, "day")
                                        .hour(9)
                                        .startOf("hour");

                                    if (
                                        job.availableForPickup.isAfter(dayjs())
                                    ) {
                                        return inFive.format(
                                            "YYYY-MM-DDTHH:mm"
                                        );
                                    }

                                    return dayjs()
                                        .add(job.bookingOpenDays || 5, "day")
                                        .hour(9)
                                        .startOf("hour")
                                        .format("YYYY-MM-DDTHH:mm");
                                    if (
                                        job.availableForPickup.isAfter(dayjs())
                                    ) {
                                        if (
                                            job.availableForPickup
                                                .add(
                                                    job.daysAllowedBeforeBooking ||
                                                        5,
                                                    "days"
                                                )
                                                .isAfter(dayjs())
                                        ) {
                                            return job.availableForPickup
                                                .add(
                                                    job.daysAllowedBeforeBooking ||
                                                        5,
                                                    "days"
                                                )
                                                .endOf("day")
                                                .format("YYYY-MM-DDTHH:mm");
                                        }
                                    }
                                    return dayjs()
                                        .add(
                                            job.daysAllowedBeforeBooking || 5,
                                            "days"
                                        )
                                        .endOf("day")
                                        .format("YYYY-MM-DDTHH:mm");
                                },
                                required: true,
                            },
                            // deliverDate: {
                            //     value: job.deliverBefore > 1 ? <p>Obs! du må levere dette bilen innom {job.deliverBefore} dager.</p> : <p>Obs! du må levere dette bilen innom 1 døgn.</p>,
                            //     type: 'free-text',
                            //     validator: () => true,
                            //     exclude: true

                            // },
                            message: {
                                value: "",
                                type: "textarea",
                                validator: () => true,
                                placeholder: labels[language]?.message,
                                icon: faEdit,
                            },
                            toc: {
                                type: "checkbox",
                                toc: <Toc html={company.betingelse} />,
                                placeholder: `Jeg godtar betingelsene`,
                                validator: () => validateEmpty,
                                required: true,
                            },
                        });
                    });
                })
                .catch(console.log);
            // Api.getTempReservation(id).then(response => {
            //     setLoading(false)

            //     setJob(response)
            // }).catch(error => {
            //     // Api.reserveTrip(id).then(response => {
            //     //     setLoading(false)

            //     //     setJob(response);

            //     // }).catch(error => {
            //     //     setLoading(false)

            //     //     setError(<div className="container error">
            //     //         <p>{error}</p>
            //     //     </div>)
            //     // });
            // });
        }
    }, [id]);

    const onTick = (timeLeft) => {};

    const onComplete = () => {
        console.log("completed: ");
        setLoading(true);

        Api.deleteReservation(id)
            .then(() => {
                setLoading(false);
                setError(
                    <div className="container error">
                        <Heading>Error</Heading>
                        <p>
                            Your reservation has expired, please refresh the
                            page and try again
                        </p>
                    </div>
                );
            })
            .catch(() => {
                window.location.reload();
            });
    };
    // useEffect(() => () => {
    //     if (!booked) {

    //         Api.deleteReservation(id);

    //     }
    // }, [booked, id]);

    if (error) {
        return error;
    }

    return (
        <div className="container reserve">
            <div className="booking">
                <FadeIn>
                    <div className="flex-col">
                        <Heading>Din bil</Heading>
                        {job && <Job {...job} />}
                    </div>
                </FadeIn>
                {!booked && (
                    <FadeIn>
                        <div className="flex-col">
                            <Heading>Reserver her</Heading>

                            {job && !booked && (
                                <Card>
                                    {/* <CardHeading>
                                <p>Din bil er midlertidlig reservert i 5 minutter </p>
    
    
                            </CardHeading> */}
                                    <CardBody>
                                        {/* {job?.temporarilyReserveUntil && !booked && <div>
                                    <Heading>Tid igjen: <Countdown onComplete={onComplete} onTick={onTick} to={job.temporarilyReserveUntil} /></Heading>
                                </div>} */}
                                        {bookingForm && (
                                            <Form
                                                hiddenFields={{ jobId: id }}
                                                onSuccess={(response) =>
                                                    setBooked(response || true)
                                                }
                                                form={bookingForm}
                                                url={"/headless/jobs/book/"}
                                                updateForm={updateForm}
                                            />
                                        )}
                                    </CardBody>
                                </Card>
                            )}
                        </div>
                    </FadeIn>
                )}
                {booked && (
                    <div className="success">
                        <Heading>Order fullført</Heading>
                        {(booked && (
                            <div dangerouslySetInnerHTML={{ __html: booked }} />
                        )) || (
                            <p>
                                Tusen takk, din bestilling er fullført. Venligst
                                sjekk inboxen din.
                            </p>
                        )}
                    </div>
                )}
            </div>

            {/* {company && <div>
                <Heading>Om avdeling</Heading>
                <Company {...company} />
            </div>} */}

            {loading && (
                <LoadingSpinner
                    dotColor="#ff7d19"
                    containerColor="rgba(0,0,0,0.7)"
                />
            )}
        </div>
    );
}
