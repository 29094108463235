import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DynamicContent from "../components/dynamicContent";
import Heading from "../components/heading";
function Page(props) {
    console.log(props);

    const { title, dynamicContent } = props;
    return (<div className="container">
        <Heading>{title}</Heading>
        <DynamicContent content={dynamicContent} />
    </div>
    );
}

export default Page;
