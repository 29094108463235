import dayjs from "dayjs";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    renderClassNames,
    labels,
    language,
    capFirst,
    pickupIcon,
    deliverIcon,
} from "../helpers/markup.helper";
import Address from "./address";
import Card from "./card/card";
import CardBody from "./card/card-body";
import CardFooter from "./card/card-footer";
import CardHeading from "./card/card-heading";
import Countdown from "./countdown/countdown";
import Heading from "./heading";

import { FcCalendar } from "react-icons/fc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";
import Rotatable from "./rotatable";
import Api from "../helpers/api.helper";

export const gearTypes = ["Manuelt", "Automatisk"];
export const carTypes = ["Personbil", "Varebil", "Lastebil", "Buss"];
export const drivingLicences = ["A", "B", "C", "D"];
export function hasBooking(job) {
    return !job.bookingName && !job.bookingDate && !job.bookingEmail;
}

export function boolToJaNei(value) {
    return value ? "Ja" : "Nei";
}

export default function Job(job) {
    const {
        carType,
        deposit,
        extraInfo,
        feeForNoShow,
        freeAdditionalCosts,
        freeFuel,
        gearType,
        requiredDrivingLicence,
        seats,
        adminFee,
        bookingOpenDays,
    } = job;
    const location = useLocation();
    const [isReserved, setReserved] = useState(
        job.temporarilyReserveUntil &&
            job.temporarilyReserveUntil.isAfter(dayjs())
    );
    const [myBooking, setBooking] = useState(
        job.temporaryReservationId &&
            job.temporaryReservationId === localStorage.getItem("reservationId")
    );
    let classNames = ["job"];
    if (isReserved) {
        classNames.push("reserved");
    }
    // const [company, setCompany] = useState();

    const counterComplete = () => {
        setReserved(
            job.temporarilyReserveUntil &&
                job.temporarilyReserveUntil.isAfter(dayjs.utc())
        );
    };

    useEffect(() => {
        setReserved(
            job.temporarilyReserveUntil &&
                job.temporarilyReserveUntil.isAfter(dayjs.utc())
        );
        setBooking(
            job.temporaryReservationId &&
                job.temporaryReservationId ===
                    localStorage.getItem("reservationId")
        );
        // Api.getCompany(job.company).then((company) => setCompany(company));
    }, [job]);
    const [isOpen, setOpen] = useState(false);

    let isAvailable = job.availableForPickup
        .subtract(job.daysAllowedBeforeBooking || 5, "days")
        .isBefore(dayjs());

    return (
        <Card classNames={renderClassNames(classNames)}>
            <CardHeading>
                <span>{job.title || carType || "Returbil"}</span>
                <button
                    className="btn btn-primary flex-row"
                    onClick={() => setOpen(!isOpen)}
                >
                    {isOpen ? (
                        <FontAwesomeIcon icon={faTimes} />
                    ) : (
                        <FontAwesomeIcon icon={faInfo} />
                    )}
                    <span>{isOpen ? "Mindre info" : "Mer info"}</span>
                </button>
            </CardHeading>
            <CardBody>
                <Rotatable open={isOpen}>
                    <div className="front">
                        <div className="bg-light py-3 px-4 text-center mb-2">
                            <Heading>{labels[language]?.pickup}</Heading>
                            <Address
                                {...job.pickupFrom}
                                iconLeft={pickupIcon()}
                            />
                            <p>
                                {labels[language]?.pickupBefore} <br />
                                <span
                                    className="shadow bg-white
                        py-1 px-3 d-inline-block"
                                >
                                    <FcCalendar size="1.5em" className="mr-3" />
                                    {capFirst(
                                        new Intl.DateTimeFormat("nb-NO", {
                                            dateStyle: "full",
                                            timeStyle: "short",
                                        }).format(job.availableForPickup)
                                    )}
                                    {/* {capFirst(
                                        job.availableForPickup.format(
                                            "dddd DD. MMMM YYYY - hh:mm"
                                        )
                                    )} */}
                                </span>
                            </p>
                        </div>
                        <div className="bg-light py-3 px-4 text-center mb-2">
                            <Heading>{labels[language]?.deliver}</Heading>
                            <Address
                                {...job.deliverTo}
                                iconRight={deliverIcon()}
                            />
                            {/* <p>
                                {labels[language]?.deliverBefore} <br />
                                <span
                                    className="shadow bg-white
                        py-1 px-3 d-inline-block"
                                >
                                    <FcCalendar size="1.5em" className="mr-3" />
                                    {job.deliverBefore > 1 ? `${job.deliverBefore} dager` : '1 døgn'}
                                </span>
                            </p> */}
                        </div>
                    </div>
                    <div className="back">
                        <ul className="carstats">
                            <li className="carstats_item">
                                Gratis drivsstoff:
                                <span className="carstats_item_value">
                                    {boolToJaNei(freeFuel)}
                                </span>
                            </li>
                            <li className="carstats_item">
                                Gratis tilleggskostnader:
                                <span className="carstats_item_value">
                                    {boolToJaNei(freeAdditionalCosts)}
                                </span>
                            </li>
                            {adminFee && (
                                <li className="carstats_item">
                                    Admin gebyr:
                                    <span className="carstats_item_value">
                                        NOK{adminFee}
                                    </span>
                                </li>
                            )}
                            <li className="carstats_item">
                                Depositum:
                                <span className="carstats_item_value">
                                    {boolToJaNei(deposit)}
                                </span>
                            </li>
                            <li className="carstats_item">
                                Gebyr for seint avbooking:
                                <span className="carstats_item_value">
                                    NOK{feeForNoShow || 0}
                                </span>
                            </li>
                            <li className="carstats_item">
                                Bil type:
                                <span className="carstats_item_value">
                                    {carTypes[carType]}
                                </span>
                            </li>
                            <li className="carstats_item">
                                Gir type:
                                <span className="carstats_item_value">
                                    {gearTypes[gearType]}
                                </span>
                            </li>
                            <li className="carstats_item">
                                Seter:
                                <span className="carstats_item_value">
                                    {seats}
                                </span>
                            </li>
                            <li className="carstats_item">
                                Førerkort:
                                <span className="carstats_item_value">
                                    {drivingLicences[requiredDrivingLicence] ||
                                        "alle"}
                                </span>
                            </li>
                            {/* <li className="carstats_item">
                                Bedrift:
                                <span className="carstats_item_value">{company?.title}</span>
                            </li> */}
                        </ul>

                        {extraInfo && (
                            <div
                                className="extra-info"
                                dangerouslySetInnerHTML={{ __html: extraInfo }}
                            />
                        )}
                    </div>
                </Rotatable>
            </CardBody>
            <CardFooter>
                {!isReserved && location.pathname !== "/reserve/" + job.id && (
                    <Link
                        to={"/reserve/" + job.id}
                        className="btn btn-primary w-100 rounded-pill py-3"
                    >
                        <span className="h4 font-weight-bold">
                            {labels[language]?.reserve}
                        </span>
                    </Link>
                )}
            </CardFooter>

            {/* {!isAvailable && (
                <span className="reserved-message">
                    Bestilling åpene om {job.availableForPickup.subtract(job.daysAllowedBeforeBooking || 5, 'days').diff(dayjs(), 'days')} dager
                    {/* <br />
                    <Countdown
                        to={job.availableForPickup.subtract(job.daysAllowedBeforeBooking || 5, 'days')}
                        onComplete={counterComplete}
                    /> }
                </span>
    )
} */}
        </Card>
    );
}
