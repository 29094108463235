import React, { useEffect, useRef, useState } from "react";

// Pass 2 containers as children and an open/closed variable switch between open and close
export default function Rotatable({ children, open }) {
    const [height, setHeight] = useState(100);
    const container = useRef();
    useEffect(() => {
        // console.log(container.current)
        let height = [...container?.current?.childNodes]?.reduce(
            (result, element) =>
                Math.max(element.getBoundingClientRect().height, result),
            0
        );
        setHeight(height);
    }, []);
    let className = "rotatable";
    if (open) {
        className += " open";
    }

    return (
        <div
            style={{ height: height + "px" }}
            ref={container}
            className={className}
        >
            {children}
        </div>
    );
}
