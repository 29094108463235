import React, { useState, useEffect, useRef } from "react";

// External components
import { IoMdClose } from "react-icons/io";
// import Lottie from "react-lottie-segments";

import { useOuterClick, capFirst } from "../helpers/markup.helper";

const Select = ({
    options,
    placeholder,
    iconLeft,
    onChange,
    searchable = false,
    placeholderValue,
    returnPlaceholderValueAtLoad = false,
}) => {
    // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    // const updateSize = () => {
    //     setScreenWidth(window.innerWidth);
    // };
    // useEffect(() => {
    //     window.addEventListener("resize", updateSize);
    // }, []);
    const [isTouch, setIsTouch] = useState(false);
    useEffect(() => {
        if (window.matchMedia("(pointer: coarse)").matches) {
            // touchscreen
            setIsTouch(true);
        } else {
            setIsTouch(false);
        }
    }, []);
    const [val, setVal] = useState("");
    const [label, setLabel] = useState("");
    const [visibleOptions, setVisibleOptions] = useState([]);
    const [optionsBackup, setOptionsBackup] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const innerRef = useOuterClick((ev) => {
        setDropdownOpen(false);
    });
    useEffect(() => {
        if (options) {
            if (typeof options[0] === "string") {
                let arr = [];
                options.forEach((opt) =>
                    arr.push({ value: opt, label: opt, key: opt })
                );
                setVisibleOptions(arr);
                setOptionsBackup(arr);
            } else {
                setVisibleOptions(options);
                setOptionsBackup(options);
            }
        }
    }, [options]);
    const search = useRef(null);
    const [searching, setSearching] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    useEffect(() => {
        if (returnPlaceholderValueAtLoad) {
            onChange(placeholderValue);
        }
    }, [placeholderValue, returnPlaceholderValueAtLoad]);
    const doSearch = (query) => {
        let q = query.toLowerCase();
        if (visibleOptions) {
            if (query.length > 0) {
                let filtered = visibleOptions.filter((opt) =>
                    JSON.stringify(opt).toLowerCase().includes(q)
                );
                setVisibleOptions(filtered);
            } else {
                setVisibleOptions(optionsBackup);
            }
        }
    };
    // useEffect(() => {
    //     if (placeholder.toLowerCase() === "henting" && screenWidth > 1200) {
    //         document.getElementById(placeholder).click();
    //     }
    // }, [placeholder, screenWidth]);
    return (
        <div
            className={`dropdown ${dropdownOpen ? "dropdown--open" : ""}
                    ${
                        val !== "" && val !== placeholder
                            ? "dropdown--selected"
                            : ""
                    }
                    ${searching ? "dropdown--focus" : ""}
                    ${iconLeft ? "dropdown--icon-left" : ""}
                    `}
            ref={innerRef}
            onClick={() => setDropdownOpen(!dropdownOpen)}
        >
            <div
                // style={{
                //     minWidth: placeholder ? placeholder.length + "ch" : "",
                // }}
                className="dropdown_toggler"
            >
                <div>
                    {iconLeft && (
                        <span className="dropdown_icon dropdown_icon--left">
                            {" "}
                            {iconLeft}
                        </span>
                    )}
                    {searchable && (
                        <input
                            ref={search}
                            type="text"
                            id={placeholder}
                            // style={{
                            //     minWidth: placeholder
                            //         ? placeholder.length + "ch"
                            //         : "",
                            // }}
                            className={`dropdown_search 
                            ${iconLeft ? "dropdown_search--icon-left" : ""}`}
                            onChange={(e) => {
                                doSearch(e.target.value);
                                setSearchQuery(e.target.value);
                            }}
                            onFocus={() => setSearching(true)}
                            onBlur={(e) =>
                                e.target.value ? null : setSearching(false)
                            }
                        />
                    )}
                    <span
                        className={`dropdown_label ${
                            searching ? "dropdown_label--hidden" : ""
                        }`}
                    >
                        {capFirst(val || placeholder)}
                    </span>
                </div>
                <span
                    className={`dropdown_icon dropdown_icon--right ${
                        val ? "dropdown_icon--right-hasvalue" : ""
                    }`}
                    onClick={() => {
                        document.getElementById(placeholder).focus();
                    }}
                >
                    {val && (
                        <span
                            className="dropdown_close"
                            onClick={() => {
                                setVal("");
                                setLabel(placeholder);
                                onChange("all");
                                document.getElementById(placeholder).focus();
                                if (searchable && search.current) {
                                    search.current.value = "";
                                    search.current.blur();
                                    setSearching(false);
                                    setSearchQuery("");
                                    doSearch("");
                                }
                            }}
                        >
                            <IoMdClose size="2em" />
                        </span>
                    )}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30.47 21.56"
                        width="1.5em"
                        className={`dropdown-checkmark ${
                            val && val !== placeholder
                                ? "dropdown-checkmark--animate"
                                : ""
                        }`}
                    >
                        <polyline
                            points="1.06 9.48 11.03 19.44 29.41 1.06"
                            style={{
                                fill: "none",
                                stroke: "#000",
                                strokeMiterlimit: 10,
                                strokeWidth: 3,
                            }}
                        />
                    </svg>
                    {/* <Lottie
                        options={{
                            loop: false,
                            autoplay: false,
                            path:
                                "https://assets7.lottiefiles.com/packages/lf20_jy2fkag5.json",
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid meet",
                            },
                        }}
                        isPaused={!val ? true : false}
                        width="3em"
                    /> */}
                </span>

                {/* {!val || val === placeholder ? (
                        <FiChevronDown className="ml-3" size="2em" />
                    ) : (
                        <FiCheck className="ml-3" size="2em" />
                    )} */}
            </div>
            {dropdownOpen && (
                <div className={`dropdown_options`}>
                    {placeholder && !searchQuery && (
                        <div
                            className={`dropdown_options_option dropdown_options_option-placeholder`}
                            onClick={() => {
                                setVal(placeholder);
                                if (placeholderValue) {
                                    onChange(placeholderValue);
                                }
                                if (searchable && search.current) {
                                    search.current.value = "";
                                    search.current.blur();
                                    setSearching(false);
                                    setSearchQuery("");
                                    doSearch("");
                                }
                            }}
                            key={placeholder.toLowerCase()}
                            value={placeholder}
                        >
                            {capFirst(placeholder)}
                        </div>
                    )}
                    {visibleOptions?.map((option, index) => (
                        <div
                            className={`dropdown_options_option`}
                            onClick={() => {
                                setVal(option.value);
                                setLabel(option.label);
                                onChange(option.value);
                                if (searchable && search.current) {
                                    search.current.value = "";
                                    search.current.blur();
                                    setSearching(false);
                                    setSearchQuery("");
                                    doSearch("");
                                }
                            }}
                            key={option.value ? option.value.toLowerCase() : ""}
                            value={option.value}
                        >
                            {capFirst(option.label)}
                        </div>
                    ))}
                </div>
            )}
        </div>
        // <div className="select-wrapper">
        //     <select
        //         onChange={(e) => {
        //             onChange(e);
        //             if (
        //                 JSON.stringify(options)
        //                     .toLowerCase()
        //                     .includes(e.target.value.toLowerCase())
        //             ) {
        //                 setSelected(true);
        //             } else {
        //                 setSelected(false);
        //             }
        //         }}
        //         style={{
        //             minWidth: placeholderLabel
        //                 ? placeholderLabel.length + "ch"
        //                 : visibleOptions[0]?.label?.length + "ch",
        //         }}
        //         className={`select ${selected ? "select--selected" : ""}`}
        //     >
        //         {placeholderLabel && (
        //             <option value={placeholderValue}>{placeholderLabel}</option>
        //         )}
        //         {visibleOptions?.map((option) => (
        //             <option
        //                 key={option.value.toLowerCase()}
        //                 value={option.value}
        //             >
        //                 {option.label.charAt(0).toUpperCase() +
        //                     option.label.slice(1)}
        //             </option>
        //         ))}
        //     </select>
        //     {iconLeft && (
        //         <div className="select-location-icon-left">{iconLeft}</div>
        //     )}
        //     {iconRight && (
        //         <div className="select-location-icon-right">{iconRight}</div>
        //     )}
        // </div>
    );
};

export default Select;
