import React from "react";
import "./loadingSpinner.scss";
export default function LoadingSpinner({ containerColor, dotColor, size }) {
    const baseStyle = {
        position: "absolute",
        display: "block",
        width: "0.7em",
        height: "0.7em",
        borderRadius: "50%",
        background: dotColor || "white",
        margin: "-0.4em 0 0 - 0.4em",
    };
    const divStyle = {
        animation: "lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite",
        transformOrigin: "4em 4em",
    };
    const rollerStyle = {
        display: "inline-block",
        position: "relative",
        width: "8em",
        height: "8em",
    };
    const loadingStyle = {
        position: "absolute",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: containerColor || "black",
        fontSize: (size || 30) + "px",
    };
    const baseDelay = 0.036;

    return (
        <div className="loading" style={loadingStyle}>
            <div className="lds-roller" style={rollerStyle}>
                <div style={{ ...divStyle, animationDelay: `${baseDelay * 8}s` }} ><div style={{ ...baseStyle, top: '6.3em', left: '6.3em' }} /></div>
                <div style={{ ...divStyle, animationDelay: `${baseDelay * 7}s` }} ><div style={{ ...baseStyle, top: '6.8em', left: '5.6em' }} /></div>
                <div style={{ ...divStyle, animationDelay: `${baseDelay * 6}s` }} ><div style={{ ...baseStyle, top: '7.1em', left: '4.8em' }} /></div>
                <div style={{ ...divStyle, animationDelay: `${baseDelay * 5}s` }} ><div style={{ ...baseStyle, top: '7.2em', left: '4.0em' }} /></div >
                <div style={{ ...divStyle, animationDelay: `${baseDelay * 4}s` }} ><div style={{ ...baseStyle, top: '7.1em', left: '3.2em' }} /></div >
                <div style={{ ...divStyle, animationDelay: `${baseDelay * 3}s` }} ><div style={{ ...baseStyle, top: '6.8em', left: '2.4em' }} /></div >
                <div style={{ ...divStyle, animationDelay: `${baseDelay * 2}s` }} ><div style={{ ...baseStyle, top: '6.3em', left: '1.7em' }} /></div >
                <div style={{ ...divStyle, animationDelay: `${baseDelay * 1}s` }} ><div style={{ ...baseStyle, top: '5.6em', left: '1.2em' }} /></div >
            </div>
        </div>
    );
}
