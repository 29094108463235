import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoIosArrowDropdown } from "react-icons/io";
import FadeIn from "react-fade-in";
export default function Toc({ html }) {
    const [open, setOpen] = useState(false);
    if (!html) {
        return null;
    }

    return (
        <div className="toc">
            <button
                type="button"
                style={{ fontSize: "0.9rem" }}
                className="btn btn-secondary"
                onClick={() => setOpen(!open)}
            >
                {open ? "Lukk" : "Les"} betingelsene{" "}
                {open ? <AiOutlineCloseCircle /> : <IoIosArrowDropdown />}
            </button>
            <div className="toc-container">
                {open && (
                    <FadeIn>
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                        <button
                            type="button"
                            style={{ fontSize: "0.9rem" }}
                            className="btn btn-secondary mt-3"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? "Lukk" : "Les"} betingelsene{" "}
                            {open ? (
                                <AiOutlineCloseCircle />
                            ) : (
                                <IoIosArrowDropdown />
                            )}
                        </button>
                    </FadeIn>
                )}
            </div>
        </div>
    );
}
