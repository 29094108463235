import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faExclamation,
    faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { renderClassNames } from "../../helpers/markup.helper";
import LoadingSpinner from "../loadingSpinner/loadingSpinner";
import DatePicker from "../DatePicker";
import PickupDropoffPicker from "../PickupDropoffPicker";
import { SingleDatePicker } from "react-dates";
import dayjs from "dayjs";
import FadeIn from "react-fade-in";

export function validateEmail(value) {
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(value);
    //  'Email is invalid';
}

export function validateEmpty(value) {
    return value !== "" && value !== undefined && value !== null;
    // 'Field is empty';
}
export function validatePhone(value) {
    let phoneFormat = /^[0-9]{0,}$/;
    return phoneFormat.test(value);
    // 'Mobile is invalid';
}

export function validatePersonNumber(value) {
    let pnFormat = /^\d{11}$/;
    return pnFormat.test(value);
    // 'Mobile is invalid';
}
export default function Form({
    form,
    updateForm,
    hiddenFields,
    url,
    classNames,
    onSuccess,
}) {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState();
    const [formError, setError] = useState();
    const validateForm = () => {
        Object.entries(form).forEach(([key, field]) => {
            if (!field.validator(field.value)) {
                return false;
            }
        });
        return true;
    };
    const submitForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!validateForm()) {
            return setLoading(false);
        }
        try {
            let formData = {};
            Object.entries(form).forEach(([key, field]) => {
                if (!field.validator(field.value)) {
                    throw key + " is invalid";
                }
                if (field.exclude) {
                    return;
                }
                if (field.type === "datepicker") {
                    formData[key] = dayjs(field.value).format();
                } else {
                    formData[key] = field.value;
                }
            });
            Object.entries(hiddenFields).forEach(([key, value]) => {
                formData[key] = value;
            });

            console.log(formData);
            let request = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(formData),
            });

            let response = await request.json();

            console.log(response);
            if (request.status !== 200) {
                throw "Could not send the form, please try again";
            }
            setLoading(true);
            setResponse(response);
            if (typeof onSuccess === "function") {
                onSuccess(response);
            }
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                setLoading(false);
                setError(error);
            }, 2000);
        }
    };

    const onChange = (e, callback) => {
        let state = {
            ...form,
        };

        console.log(e.target.name);
        if (state[e.target.name] === undefined) {
            return;
        }
        state[e.target.name].value = e.target.value;
        state[e.target.name].error = form[e.target.name].validator(
            e.target.value
        );
        setError();
        if (typeof callback === "function") {
            callback(e);
        }
        updateForm(state);
    };

    let submitClass = loading ? "sending" : "ready";
    let disabled = loading || formError;

    if (!url) {
        return null;
    }
    return (
        <section>
            <div className={renderClassNames("form-container", classNames)}>
                <form onSubmit={submitForm}>
                    <FadeIn delay={10}>
                        {Object.entries(form).map(([key, field]) => (
                            <div
                                key={key}
                                className={
                                    "input-with-icon" +
                                    (field.error ? " error" : "")
                                }
                            >
                                {key === "message" ? (
                                    <textarea
                                        name={key}
                                        rows={5}
                                        placeholder={field.placeholder}
                                        value={field.value}
                                        onChange={onChange}
                                    />
                                ) : field.type === "datepicker" ? (
                                    <>
                                        <label>{field.placeholder}</label>
                                        <input
                                            style={{ paddingRight: "0.5em" }}
                                            type="date"
                                            name={key}
                                            value={field.value}
                                            placeholder={field.placeholder}
                                            onChange={(e) =>
                                                onChange(e, field.onChange)
                                            }
                                            min={field.min(form)}
                                            max={field.max(form)}
                                        />
                                    </>
                                ) : field.type === "checkbox" ? (
                                    <div>
                                        <label
                                            style={{
                                                flex: "1 1 auto",
                                                maxWidth: "100%",
                                            }}
                                            className="d-flex align-items-center w-100 justify-content-between cursor-pointer btn-light btn justify"
                                        >
                                            {field.placeholder}
                                            <input
                                                style={{
                                                    paddingRight: "0.5em",
                                                }}
                                                type="checkbox"
                                                name={key}
                                                value={field.value}
                                                className="mb-0"
                                            />
                                        </label>
                                        {field.toc}
                                    </div>
                                ) : field.type === "free-text" ? (
                                    <div>{field.value}</div>
                                ) : (
                                    <input
                                        type="text"
                                        name={key}
                                        placeholder={field.placeholder}
                                        value={field.value}
                                        onChange={onChange}
                                    />
                                )}
                                {field.icon && (
                                    <FontAwesomeIcon icon={field.icon} />
                                )}
                                <p className="error">{field.error}</p>
                            </div>
                        ))}

                        {loading && (
                            <LoadingSpinner
                                dotColor="#ff7d19"
                                containerColor="rgba(0,0,0,0.7)"
                            />
                        )}
                        {formError && (
                            <div className="alert alert-danger errors">
                                <p>{formError}</p>
                            </div>
                        )}
                        <button
                            disabled={disabled}
                            className={`${submitClass} btn btn-primary w-100 d-flex justify-content-between btn-lg`}
                            type="submit"
                        >
                            <span className="font-weight-bold">
                                Reserver nå
                            </span>
                            {response ? (
                                <FontAwesomeIcon icon={faCheckCircle} />
                            ) : formError ? (
                                <FontAwesomeIcon icon={faExclamation} />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faPaperPlane}
                                    className="plane"
                                />
                            )}
                        </button>
                    </FadeIn>
                </form>
            </div>
        </section>
    );
}
