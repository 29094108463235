import React from "react";
import Heading from "../components/heading";
import Jobs from "../components/jobs";
import { useEffect, useState } from "react";
import Api from "../helpers/api.helper";
import { labels, language } from "../helpers/markup.helper";
import FilterableList from "../components/listFilters/filterableList";
import { useLocation } from "react-router";
import LoadingSpinner from "../components/loadingSpinner/loadingSpinner";
import { useCallback, useContext } from "react";
import { HubContext } from "../hubContextProvider";
let interval;
export default function Home() {
    const [jobs, setJobs] = useState();
    const location = useLocation();
    const fetchJobs = async () => {
        setJobs(await Api.getJobs());
        console.log(jobs)
    };
    const { connection, proxy } = useContext(HubContext);

    useEffect(() => {
        interval = setInterval(() => fetchJobs(), 60000);

        return () => clearInterval(interval);
    }, [interval]);

    const updateFunction = useCallback(() => {
        console.log("updating");
        fetchJobs();
    });

    useEffect(() => {
        console.log("connecting to job hub", connection, proxy);
        proxy?.on("update", updateFunction);

        return () => {
            console.log("unsubscribing updates");
            proxy?.off("update", updateFunction);
        };
    }, [connection]);

    useEffect(() => {
        fetchJobs();
    }, [location]);

    return (
        <div className="container home">
            {/* <Heading>{labels[language]?.heading}</Heading> */}

            {!jobs && (
                <div>
                    <LoadingSpinner dotColor="#ff7d19" containerColor="rgba(0,0,0,0.7)" />
                </div>
            )}
            {jobs && (
                <section>
                    <FilterableList
                        heading={labels[language]?.subHeading}
                        listItems={jobs}
                        listComponent={Jobs}
                    />
                </section>
            )}
        </div>
    );
}
