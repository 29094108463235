import React from "react";
import Heading from "../components/heading";
export default function NotFound() {
    return (
        <div className="d-flex justify-content-center flex-wrap flex-column w-100 p-5">
            <Heading>Siden finnes ikke</Heading>
            <p>
                Oi sann! <br />
                Her har du kommet til en side som ikke eksisterer.
            </p>
        </div>
    );
}
