import "./App.scss";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./pages/home";
import React, { useState } from "react";
import { useEffect } from "react";
import Api from "./helpers/api.helper";
import Reserve from "./pages/reserve";
import "react-datepicker/dist/react-datepicker.css";
import NotFound from "./pages/404";
import Heading from "./components/heading";
import "signalr/jquery.signalR.min";
import Form, {
    validateEmail,
    validateEmpty,
    validatePhone,
} from "./components/form/form";
import {
    faEdit,
    faEnvelope,
    faMobileAlt,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import Page from "./pages/page";

// Helpers
import { labels, language } from "./helpers/markup.helper";

function App() {
    const [pageTree, setPageTree] = useState();
    useEffect(() => {
        const getPages = async () => {
            const pagesResponse = await Api.getPageTree();
            setPageTree(pagesResponse);
        };

        getPages();
    }, []);

    const [contactForm, updateForm] = useState({
        name: {
            value: "",
            type: "text",
            validator: validateEmpty,
            placeholder: labels[language]?.name,
            icon: faUser,
        },
        email: {
            value: "",
            type: "email",
            validator: validateEmail,
            placeholder: labels[language]?.email,
            icon: faEnvelope,
        },
        mobile: {
            value: "",
            type: "text",
            validator: validatePhone,
            placeholder: labels[language]?.phone,
            icon: faMobileAlt,
        },
        message: {
            value: "",
            rows: 20,
            validator: () => true,
            placeholder: labels[language]?.message,
            icon: faEdit,
        },
    });

    return (
        <div className="app">
            <Router>
                <header className="app-header">
                    <Link to="/">{labels[language]?.home}</Link>
                    {pageTree?.children.map((page) => (
                        <Link key={page.key} to={page.url}>
                            {page.title}
                        </Link>
                    ))}
                </header>
                <Switch>
                    <Route key="home" exact={true} path="/" component={Home} />

                    <Route
                        key="reserve"
                        exact={true}
                        path="/reserve/:id"
                        component={Reserve}
                    />

                    {pageTree?.children.map((page) => (
                        <Route
                            key={page.key}
                            path={page.url}
                            component={(props) => <Page {...props} {...page} />}
                        />
                    ))}

                    <Route key="404" path="/*" component={NotFound} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
