import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
function DynamicContent({ content }) {
    console.log(content);
    switch (content.type) {
        case "richText":
            return <div dangerouslySetInnerHTML={{ __html: content.content }}>

            </div>
    }

    return null
}

export default DynamicContent;
