import React, { useContext, useEffect, useState } from "react";

// Internal components
import Heading from "../heading";
import PlacesFilter from "./placesFilter";
import Dropdown from "../Select";
import DatePicker from "../DatePicker";
import moment from "moment";
import {
    capFirst,
    labels,
    language,
    pickupIcon,
    deliverIcon,
} from "../../helpers/markup.helper";

// External components
// import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { FcCalendar } from "react-icons/fc";
import { BsArrowRight } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import FadeIn from "react-fade-in";
import {
    DateRangePicker,
    SingleDatePicker,
    DayPickerRangeController,
} from "react-dates";

export default function FilterableList({ heading, listItems, listComponent }) {
    const [pickup, setPickup] = useState();
    const [deliver, setDelivery] = useState();
    const [pickupDate, setPickupDate] = useState();
    const [timeframe, setTimeframe] = useState(labels[language]?.weekLabel);
    const [focusPickup, setFocusPickup] = useState(false);
    require(`dayjs/locale/nb`);
    dayjs.locale("nb");
    const [deliverDate, setDeliverDate] = useState(
        dayjs().add(1, timeframe).toDate()
    );

    useEffect(() => {
        if (timeframe !== labels[language]?.customDateLabel) {
            if (timeframe === labels[language]?.weekLabel) {
                setPickupDate(dayjs().startOf("week").toDate());
                setDeliverDate(dayjs().endOf("week").toDate());
            } else if (timeframe === labels[language]?.monthLabel) {
                setPickupDate(dayjs().startOf("month").toDate());
                setDeliverDate(dayjs().endOf("month").toDate());
            }
        }
    }, [timeframe]);
    // Effect to change the delivery date if the pickup date changes to after delivery, deliver should always be same day of after pickup
    useEffect(() => {
        if (dayjs(deliverDate).isBefore(dayjs(pickupDate))) {
            console.log("updatingDelivery");
            setDeliverDate(dayjs(pickupDate).toDate());
        }
    }, [pickupDate, deliverDate]);

    const filtered = listItems?.filter(
        (item) => {
            return (
                (!pickup ||
                    pickup === "all" ||
                    item.pickupFrom?.city === pickup) &&
                (!deliver ||
                    deliver === "all" ||
                    item.deliverTo?.city === deliver)
            );
            // &&
            // (!pickupDate || item.availableForPickup.isBefore(dayjs(pickupDate)))
        }
        // && dayjs(deliverDate).isBefore(item.deliverBefore)
    );
    const [visiblePickup, setVisiblePickup] = useState(listItems);
    const [visibleDelivery, setVisibleDelivery] = useState(listItems);
    useEffect(() => {
        if (pickup === "" || pickup === "all") {
            setVisibleDelivery(listItems);
        } else {
            let filtered = visibleDelivery.filter(
                (item) => item?.pickupFrom?.city === pickup
            );
            setVisibleDelivery(filtered);
        }
    }, [pickup]);
    useEffect(() => {
        if (deliver === "" || deliver === "all") {
            setVisiblePickup(listItems);
        } else {
            let filtered = visiblePickup.filter(
                (item) => item?.deliverTo?.city === deliver
            );
            setVisiblePickup(filtered);
        }
    }, [deliver]);
    const [resetKey, setResetKey] = useState(0);
    return (
        <>
            <div className="list-filters">
                <Heading>{heading}</Heading>
                <div className="list-filters_grid">
                    <PlacesFilter
                        placeholder={labels[language]?.pickup}
                        listItems={visiblePickup}
                        onChange={(val) => setPickup(val)}
                        placeholderValue="all"
                        searchable={true}
                        returnPlaceholderValueAtLoad={true}
                        iconLeft={pickupIcon()}
                        showPickup
                        key={"pickup" + resetKey}
                    />
                    {/* <div className="d-md-none px-4 bg-light py-3">
                        <BsArrowRight size="2rem" />
                    </div> */}
                    <PlacesFilter
                        placeholder={labels[language]?.deliver}
                        listItems={visibleDelivery}
                        onChange={(val) => setDelivery(val)}
                        placeholderValue="all"
                        searchable={true}
                        showDelivery
                        returnPlaceholderValueAtLoad={true}
                        iconLeft={deliverIcon()}
                        key={"delivery" + resetKey}
                    />
                    {(deliver !== "all" || pickup !== "all") && (
                        <FadeIn>
                            <button
                                className="btn btn-outline-primary d-inline-flex align-items-center mx-auto"
                                onClick={() => {
                                    setDelivery("all");
                                    setPickup("all");
                                    setResetKey(Math.random());
                                }}
                            >
                                <FaTimes size="2rem" className="mr-2" />
                                Fjern valg
                            </button>
                        </FadeIn>
                    )}

                    {/* <div className="list-filters_date">
                        <Dropdown
                            options={[
                                {
                                    value: labels[language]?.weekLabel,
                                    label: labels[language]?.weekLabel,
                                },
                                {
                                    value: labels[language]?.monthLabel,
                                    label: labels[language]?.monthLabel,
                                },
                                {
                                    value: labels[language]?.customDateLabel,
                                    label: labels[language]?.customDateLabel,
                                },
                            ]}
                            iconLeft={
                                <FcCalendar size="1.5em" className="mr-3" />
                            }
                            onChange={(val) => setTimeframe(val)}
                            placeholder={labels[language]?.date}
                        /> */}
                    {/* <select onChange={(e) => setTimeframe(e.target.value)}>
                            <option value="week">This week</option>
                            <option value="month">This month</option>
                            <option value="custom">Custom</option>
                        </select> */}

                    {/* {timeframe === labels[language]?.customDateLabel && (
                            <DatePicker
                                setStart={setPickupDate}
                                setEnd={setDeliverDate}
                            // placeholderText="from"
                            // selected={pickupDate}
                            // onChange={(date) => setPickupDate(date)}
                            />
                        )} */}
                    {/* 
                    <SingleDatePicker
                        placeholder="Ledig fra"
                        date={moment(pickupDate)}
                        showDefaultInputIcon={true}
                        onDateChange={(date) => {
                            setPickupDate(moment(date))
                            console.log(date)
                        }}
                        inputIconPosition="after"
                        onFocusChange={({ focused }) => setFocusPickup(focused)}
                        focused={focusPickup}
                        showCaret={!focusPickup}
                    /> */}
                </div>
            </div>
            <div
                className={`filter-info bg-info px-4 pt-4 pb-3 w-100 text-center my-4 font-weight-bold shadow `}
            >
                {(pickup || deliver) && (
                    <>
                        <p>
                            Viser biler{" "}
                            {pickup && (
                                <>
                                    {labels[language]?.resultStart}{" "}
                                    <span className="h3 px-2 mx-1 bg-white py-1 shadow">
                                        {pickup.toLowerCase() !== "all"
                                            ? capFirst(pickup)
                                            : labels[language]?.anyDestination}
                                    </span>
                                </>
                            )}
                            {pickup && deliver && labels[language]?.and}
                            {deliver && (
                                <>
                                    {labels[language]?.resultEnd}{" "}
                                    <span className="h3 px-2 mx-1 bg-white py-1 shadow">
                                        {deliver.toLowerCase() !== "all"
                                            ? capFirst(deliver)
                                            : labels[language]?.anyDestination}
                                    </span>
                                </>
                            )}
                        </p>
                    </>
                )}
                {/* <p> */}
                {/* <>
                    {labels[language]?.pickupDate}
                    <span className="h3 px-2 mx-1 bg-white py-1 shadow">
                        {dayjs(pickupDate).format("DD MMMM YYYY")}
                    </span>{" "}
                    {labels[language]?.deliveryDate}
                    <span className="h3 px-2 mx-1 bg-white py-1 shadow">
                        {dayjs(deliverDate).format("DD MMMM YYYY")}
                    </span>
                </> */}
                {/* {labels[language]?.pickupDate}{" "}
                    {timeframe === "week" || timeframe === "month"
                        ? labels[language]?.deliveryDate
                        : ""}
                    {timeframe === "week" && (
                        <>
                            <span className="h3 px-2 mx-1 bg-white py-1 shadow">denne uken</span>
                            <br />(
                            {capFirst(
                                dayjs(pickupDate).format("DD MMMM")
                            )} - {dayjs(deliverDate).format("DD MMMM")})
                        </>
                    )}
                    {timeframe === "month" && (
                        <>
                            <span className="h3 px-2 mx-1 bg-white py-1 shadow">denne måneden</span>
                            <br />(
                            {capFirst(
                                dayjs(pickupDate).format("DD MMMM")
                            )} - {dayjs(deliverDate).format("DD MMMM")})
                        </>
                    )}
                    {timeframe === "custom" && (
                        <>
                            <span className="h3 px-2 mx-1 bg-white py-1 shadow">
                                {dayjs(pickupDate).format("DD MMMM YYYY")}
                            </span>{" "}
                            {labels[language]?.deliveryDate}
                            <span className="h3 px-2 mx-1 bg-white py-1 shadow">
                                {dayjs(deliverDate).format("DD MMMM YYYY")}
                            </span>
                        </>
                    )} */}
                {/* </p> */}
            </div>
            <div>{listComponent({ items: filtered })}</div>
        </>
    );
}
