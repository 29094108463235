import React from "react";
export default function Address({
    line1,
    city,
    country,
    zip,
    iconLeft,
    iconRight,
}) {
    return (
        <address className="address">
            {iconLeft && <span className="address_icon">{iconLeft}</span>}
            <div className="address_grid">
                <span>{line1}</span>
                <span>{city}</span>
                <span>{zip}</span>
            </div>
            {iconRight && <span className="address_icon">{iconRight}</span>}
        </address>
    );
}
