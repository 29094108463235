import React, { useContext } from "react";
import Job from "./job";
import { FcCancel } from "react-icons/fc";
import FadeIn from "react-fade-in";

// Helpers
import { labels, language } from "../helpers/markup.helper";

export default function Jobs({ items }) {
    return (
        <div
            className={`${
                items?.length === 0 ? "bg-danger" : "bg-info"
            } px-4 pt-4 pb-3 w-100 text-center my-4 font-weight-bold shadow `}
        >
            {items?.length === 0 && (
                <p className="text-center w-100">
                    <FcCancel className="mr-3" size="2em" />{" "}
                    {labels[language]?.noCars}
                </p>
            )}
            <FadeIn className="jobs">
                {items?.map((job) => (
                    <FadeIn>
                        <Job key={job.key} {...job} />
                    </FadeIn>
                ))}
            </FadeIn>
        </div>
    );
}
