import React, { useState } from "react";

// Internal components
import Dropdown from "../Select";

import { BiMap } from "react-icons/bi";

export default function PlacesFilter({
    listItems,
    onChange,
    placeholder,
    placeholderValue,
    searchable,
    iconLeft,
    returnPlaceholderValueAtLoad,
    showPickup,
    showDelivery,
}) {
    const getPlaces = () => {
        return listItems?.reduce((result, item) => {
            if (
                showPickup &&
                item.pickupFrom &&
                !result.includes(item.pickupFrom.city) &&
                item?.pickupFrom?.city
            ) {
                result.push(item.pickupFrom.city);
            }
            if (
                showDelivery &&
                item.deliverTo &&
                !result.includes(item.deliverTo.city) &&
                item?.deliverTo?.city
            ) {
                result.push(item.deliverTo.city);
            }
            return result.sort();
        }, []);
    };
    const customStyles = {};

    const places = getPlaces();
    const [selected, setSelected] = useState(false);
    return (
        <>
            {/* <Select
                styles={customStyles}
                classNamePrefix="select"
                defaultValue="Velg destinasjon"
                isSearchable={false}
                isClearable={true}
                defaultValue={{ label: "Velg destinasjon", value: 0 }}
                options={places?.map((place) => ({
                    value: place,
                    label: place,
                }))}
            /> */}
            <Dropdown
                options={places}
                iconLeft={iconLeft}
                icon="map marker alternate"
                onChange={onChange}
                placeholder={placeholder}
                placeholderValue={placeholderValue}
                searchable={searchable}
                returnPlaceholderValueAtLoad={returnPlaceholderValueAtLoad}
            />
            {/* <div className="select-wrapper">
                <select
                    onChange={(e) => {
                        onChange(e);
                        if (places.includes(e.target.value)) {
                            setSelected(true);
                        } else {
                            setSelected(false);
                        }
                    }}
                    className={`select ${selected ? "select--selected" : ""}`}
                >
                    <option value={"all"}>{placeholder}</option>
                    {places?.map((place) => (
                        <option key={place.toLowerCase()}>
                            {place.charAt(0).toUpperCase() + place.slice(1)}
                        </option>
                    ))}
                </select>
            </div> */}
        </>
    );
}
