//Pass an array of strings or a string
//Returns the base classname plus the additional classes
//Default returns only the base

import { useRef, useEffect } from "react";
export function renderClassNames(base, classes) {
    if (typeof classes === "string") {
        return `${base} ${classes}`;
    }
    if (Array.isArray(classes)) {
        return `${base} ${classes.join(" ")}`;
    }
    return base;
}

export function secondify(time) {
    if (time < 0) {
        return "00";
    }
    if (time < 10) {
        return "0" + time;
    }
    return time;
}
export function useOuterClick(callback) {
    const callbackRef = useRef(); // initialize mutable callback ref
    const innerRef = useRef(); // returned to client, who sets the "border" element

    // update callback on each render, so second useEffect has most recent callback
    useEffect(() => {
        callbackRef.current = callback;
    });
    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
        function handleClick(e) {
            if (
                innerRef.current &&
                callbackRef.current &&
                !innerRef.current.contains(e.target)
            )
                callbackRef.current(e);
        }
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself)
}
export function capFirst(val) {
    if (val) {
        return val.charAt(0).toUpperCase() + val.slice(1);
    } else {
        return "";
    }
}
export const language = "norsk";
export const labels = {
    norsk: {
        heading: "Leiebil retur",
        subHeading: "Finn din bil",
        and: " og ",
        noCars: `Beklager! Det er
        ingen biler tilgjengelig innenfor valgte kriterier.`,
        pickup: `Velg hentested`,
        deliver: `Velg drop-off`,
        date: "Velg dato",
        home: "Hjem",
        email: "E-post",
        name: "Navn",
        message: "Melding",
        phone: "Telefonnummer",
        resultStart: "som kan hentes i: ",
        resultEnd: "leveres til: ",
        pickupDate: "Tilgjengelig for henting:",
        deliveryDate: "og leveres innen: ",
        anyDestination: "ubestemt",
        pickupBefore: "Hentes fra:",
        deliverBefore: "Leveres innom:",
        pickup: "Henting",
        deliver: "Levering",
        reserve: "Reserver",
        reservedUntil: "Reservert tid:",
        weekLabel: "Denne uken",
        monthLabel: "Denne måneden",
        customDateLabel: "egendefindert tidsrom",
        datePickerStart: "Fra dato",
        datePickerEnd: "til dato",
    },
};
export const pickupIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 73.64 63.37"
        height="1.5em"
        className="mr-3"
    >
        <path
            fill="#ff7d19"
            strokeWidth="5"
            d="M38.36 19.93c0 18.16-18.43 41-18.43 41S1.5 37.17 1.5 19.93a18.43 18.43 0 0136.86 0z"
        ></path>
        <circle cx="19.93" cy="19.6" r="9.25" fill="white"></circle>
        <path
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M25.28 61.74q.48 0 1 .06"
        ></path>
        <path
            fill="none"
            stroke="#000"
            strokeDasharray="1.9584976434707642,4.896244049072266"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M31.16 61.7a22.87 22.87 0 0011.16-3.92c5.8-4.09 6.21-9 11.89-13.08 2.94-2.11 7.55-4.2 15-4.14"
        ></path>
        <path
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M71.64 40.66l1 .08"
        ></path>
    </svg>
);

export const deliverIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 75.6 64.32"
        height="1.5em"
        className="mr-3"
    >
        <path
            fill="#ff7d19"
            strokeWidth="5"
            d="M74.1 19.93c0 18.16-18.43 41-18.43 41s-18.43-23.78-18.43-41a18.43 18.43 0 1136.86 0z"
        ></path>
        <circle cx="55.67" cy="19.6" r="9.25" fill="white"></circle>
        <path
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M48.36 63.26q-.48 0-1 .06"
        ></path>
        <path
            fill="none"
            stroke="#000"
            strokeDasharray="1.9584976434707642,4.896244049072266"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M42.48 63.22a22.91 22.91 0 01-11.16-3.92c-5.8-4.09-6.21-9-11.89-13.08-2.94-2.11-7.55-4.2-15-4.13"
        ></path>
        <path
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M2 42.18l-1 .08"
        ></path>
    </svg>
);
